@import "@/assets/scss/main.scss";

#pdf-export-root {
    // Hide in production.
    visibility: hidden;
    height: 0px;
    overflow: hidden;
}

#pdf-export-content * {
    font-family: 'Helvetica Neue', 'Lucida Sans', Helvetica, Arial, 'sans-serif';
    box-sizing: border-box;
}
