@import "@/assets/scss/main.scss";

.close {

    background-color: red;
}

button {
    display: flex;
    flex-direction: row;
    padding: 16px 20px 16px 20px;
    gap: 12px;
    width: 200px;
    height: 47px;
    border-radius: 3px;

    font-style: normal;
    font-weight: 400;
    font-size: 15.2px;
    line-height: 100%;
    /* or 15px */
    color: #FFFFFF;
    text-decoration: none;
    border: 0 none;


}

.download {
    background: darken($elsi-color-blue, 8);
    color: $white;
    margin-left: auto;
    justify-content: center;
    width: 240px;

    .active {
        background: darken($elsi-color-blue, 8);
        color: $white;
    }
}

.btn-primary {
    background: $elsi-color-mittelgrau;
    color: $elsi-color-schrift-normal;

    &:hover {
        background: darken($elsi-color-blue, 8);
        color: $white;
    }

    .not-active {
        background: $elsi-color-mittelgrau;
    }

    &.active {
        background: darken($elsi-color-blue, 8);
        color: $white;
    }
}

.project-creation-card {
    border: none;
    padding: 20px;
    width: fit-content;
}

.project-creation {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-gap: 1.5rem;
    margin: 0;
    padding: 0;
}
