@import "@/assets/scss/main.scss";

.tutorial {
    display: flex;
}

.icon-and-text {
    display: grid;
    grid-template-columns: 5rem auto;
    grid-column-gap: 1.5rem;

    /deep/ .icon {
        justify-content: right;
        align-self: start;
    }
}
