@import "@/assets/scss/main.scss";

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 1920px) {
    .sidebar {
        display: flex;
        flex-direction: column;
        position: fixed;
        height: 100%;
        width: 80px;
        background: #FFFFFF;

        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
        border-radius: 0px 5px 5px 0px;

        .icon {
            scale: 0.8;
        }

        &:hover {
            width: 260px;
            z-index: 1;
            border-right: 2px solid darkgrey;

            .sidebar-item {
                width: 100%;
                justify-content: left;
                display: flex;
                align-self: left;
                align-items: left;
                padding-left: 26px;
            }
        }
    }
}

@media only screen and (min-width: 1921px) {
    .sidebar {
        display: flex;
        flex-direction: column;
        position: fixed;
        gap: 4rem;
        height: 100%;
        width: 80px;
        background: #FFFFFF;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
        border-radius: 0px 5px 5px 0px;

        &:hover {
            width: 260px;
            z-index: 1;

            .sidebar-item {
                width: 100%;
                justify-content: left;
                display: flex;
                align-self: left;
                align-items: left;
                padding-left: 26px;
            }
        }
    }
}

.sidebar-item {
    width: 100%;
    justify-content: center;
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: row;
    border-left: 5px solid #fff0;
}

.active {
    border-left: 5px solid $elsi-color-lightblue;
}

.sidebar-item.trenner {
    border-bottom: 3px solid $elsi-color-backdrop;
}

.icon {
    padding-bottom: 1rem;
    padding-top: 1rem;
    color: $elsi-color-schrift-normal;
}

.icon-active {
    color: $elsi-color-lightblue !important;
}

.active-nav-text {
    color: $elsi-color-lightblue !important;
}

.nav-text {
    color: $elsi-color-schrift-normal;

    &:hover {
        color: $elsi-color-lightblue;
    }
}

.nav-footer {
    position: absolute;
    bottom: 16px;
    width: inherit;
    justify-content: center;
    align-items: center;
}

.word {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}
