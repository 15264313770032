@import "@/assets/scss/main.scss";

.aufgabe {
    color: $elsi-color-aufgabe;
}

section#header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 32px 32px 0px 0px;
    gap: 20px;
    width: 100%;
    height: 100px;

    .heading {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

}

.icon-item {
    background-color: #ECF0F3;
    margin-left: 1rem;
    border-radius: 3px;

    &:hover {
        background-color: white;
    }

}
